@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    overflow: hidden;
}


@media print {
    .page-break {
        margin-top: 1rem;
        display: block;
        /*page-break-before: auto;*/
    }
}

@page {
    size: auto;
    margin: 10mm;
}

.loading-indicator:before {
    content: '';
    background: #00000080;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
}

.loading-indicator:after {
    content: ' ';
    position: fixed;
    top: 40%;
    left: 45%;
    z-index: 10010;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 1.2rem;
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #0474bf; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

.btn-ghost:hover {
    background-color: rgba(206, 206, 206, 0.23);
}

.table th:first-child {
    position: sticky;
    z-index: 5;
}

.noScrollBar::-webkit-scrollbar {
    display: none;
    width: 0px;
}

button:disabled {
    opacity: 0.4 !important;
    --tw-text-opacity: 0.6 !important;
    background-color: rgb(239, 242, 245) !important;
    cursor: not-allowed !important;
    pointer-events: all !important;
}

::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
    background-color: #e8e8e8 !important;
    padding: 0 0 0 20px !important;
    /*display: none;*/
}

::-webkit-scrollbar-thumb {
    background: #c9c9c9;
}

@media only screen and (max-width: 767px) {
    .modal-box {
        position: absolute !important;
        bottom: 0 !important;
        width: 100vw !important;
        border-radius: 25px 25px 0 0;
        min-height: 65svh;
        animation-name: modalfadeInUp !important;
        -webkit-animation-name: modalfadeInUp !important;
    }

    .modal {
        animation-name: modalfadeInUp !important;
        animation: none !important;
    }


}

@-webkit-keyframes modalfadeInUp {
    from {
        transform: translate3d(0, 25px, 0);
        opacity: 0.4;
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}