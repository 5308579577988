.ReactQuillDisplay {
    margin-left: 0;
    width: 100%;
    padding-top: 10px;
    /*padding: 20px;*/
    /*border-radius: 0.75em;*/
    /*border: solid #e8e8e8 1px !important;*/
    /*background-color: white !important;*/
    /*box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.06), 0px 6px 10px 0px rgba(0, 0, 0, 0.042), 0px 1px 18px 0px rgba(0, 0, 0, 0.036);*/
    /*margin: 10px 0 10px 0;*/
}

.ReactQuillDisplay > * {
    margin: 0 !important;
    font-size: 18px;
    line-height: 1.7em;
    font-family: 'Merriweather', serif;
    letter-spacing: 0;
    padding: 0;
    font-weight: 400;
    /*overflow-wrap: break-word;*/
}
